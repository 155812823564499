import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { CategoryItem } from './CategoryItem'
import { mapStateToProps } from './mapStateToProps'

import Styles from './styles.module.css'

import {
    setOrderContext,
} from '../../../../actions'

const imgCategories = ['boisson', "pain", "épicerie", "fruits", "poisson", "laitiers", "traiteur", "viande", "volaille"]

export class CategoryScroller extends Component {
    static propTypes = {
        family: PropTypes.string,
        category: PropTypes.string,
        categories: PropTypes.array.isRequired,
        handleMenuClick: PropTypes.func,
        updateitemIndex: PropTypes.func,
        scrollerItems: PropTypes.array.isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            scrollerItems: [],
            modalOpen: undefined,
            error: false,
            open: false,
        }

        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {
        if (this.props.scrollerItems[0].category_id) {
            this.props.setOrderContext({ category: this.props.scrollerItems[0].category_id, family: this.props.scrollerItems[0]._id, selectedCategory: this.props.scrollerItems[0].category_id, selectedFamily: this.props.scrollerItems[0]._id })
        }
        else {
            this.props.setOrderContext({ category: this.props.categories[0]._id, family: null, selectedCategory: this.props.categories[0]._id, selectedFamily: null })
        }

    }

    handleClick({ family, category, selectedCategory, selectedFamily }) {
        this.props.handleMenuClick({ family, category, selectedCategory, selectedFamily })
        this.props.updateitemIndex(selectedCategory === 'promotions' ? selectedFamily : selectedCategory)
    }

    render() {
        const { selectedCategory, selectedFamily } = this.props

        return (
            <div
                className={Styles.MenuContainer}
                data-testid="menu-categories"
                id="menu-mobile"
            >
                <div id="menu-scroller" className={Styles.scroller}>
                    {this.props.scrollerItems && this.props.scrollerItems.map(item => {
                        return (
                            <CategoryItem
                                imgLight={imgCategories.find(i => item?.name?.toLowerCase().includes(i)) + '_clair'}
                                imgDark={imgCategories.find(i => item?.name?.toLowerCase().includes(i)) + '_fonce'}
                                scrollerItem={item}
                                selectedCategory={selectedCategory}
                                selectedFamily={selectedFamily}
                                handleClick={this.handleClick}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }
}

CategoryScroller.propTypes = {
    categories: PropTypes.array,
    family: PropTypes.string,
    category: PropTypes.string,
    sessionCategory: PropTypes.string,
    hasMultipleActiveGlobalOrders: PropTypes.bool,
    handleMenuClick: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => {
    return {
        setOrderContext: params => dispatch(setOrderContext(params)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryScroller)
