import 'whatwg-fetch'
import { run as isObjectEmpty } from '@kelbongoo/shared-methods/utils/isObjectEmpty'
import * as Types from '../actions/types'
import { API_ADDRESS } from '../app-constants'

const isTesting = !['staging', 'production'].includes(process.env.NODE_ENV)

const formatFailureMessage = ({ message, error }) => {
    try {
        return { message: JSON.parse(message).message }
    } catch (e) {
        return {
            message: message || error.message || "Une erreur s'est produite",
        }
    }
}

export const wrapClientApiCall = ({
    actionRoot,
    url,
    method = 'GET',
    body,
    token,
    accept = 'application/json',
    contentType = 'application/json',
    hasJsonResponse,
    prerequestHook,
    successPayload,
    successCallback,
    failurePayload,
    failureCallback,
}) => {
    return (dispatch, getState) => {
        if (
            !Types[`${actionRoot}_FAIL`] ||
            !Types[`${actionRoot}_REQUEST`] ||
            !Types[`${actionRoot}_SUCCESS`] ||
            !Types.TOKEN_EXPIRED
        ) {
            throw new Error(`Action type for ${actionRoot} missing`)
        }

        if (actionRoot === 'UPDATE_CONSUMER_DATA') {
            console.log('body', body)
        }

        failureCallback =
            failureCallback ||
            (({ dispatch, message, error = {} }) => {
                const failureData = failurePayload
                    ? failurePayload({ message, error })
                    : formatFailureMessage({ message, error })

                dispatch({
                    type: Types[`${actionRoot}_FAIL`],
                    ...failureData,
                })

                return false
            })

        successCallback =
            successCallback ||
            (({ response, dispatch, data = {} }) => {
                dispatch({
                    type: Types[`${actionRoot}_SUCCESS`],
                    ...(successPayload ? successPayload(data) : data),
                })

                if (data && !isObjectEmpty(data)) {
                    return data
                } else {
                    return true
                }
            })

        if (typeof prerequestHook === 'function') {
            const prerequestResult = prerequestHook({
                dispatch,
                body,
                url,
                getState,
            })

            if (prerequestResult && prerequestResult.body) {
                body = prerequestResult.body
            }
            if (prerequestResult && prerequestResult.url) {
                url = prerequestResult.url
            }

            if (prerequestResult && prerequestResult.abandon) {
                return Promise.resolve()
            }
        }

        dispatch({
            type: Types[`${actionRoot}_REQUEST`],
        })

        const currentUser = getState().currentUser

        token = token || (currentUser ? currentUser.token : undefined)
        const tokenRequired = url.slice(0, 4) === 'api/'

        if (tokenRequired && !token) {
            throw new Error('No user token found')
        }

        hasJsonResponse =
            typeof hasJsonResponse !== 'undefined'
                ? hasJsonResponse
                : method === 'GET'

        return fetch(`${API_ADDRESS}/${url}`, {
            method,
            ...(body && { body: JSON.stringify(body) }),
            headers: {
                'Content-Type': contentType,
                Accept: accept,
                Authorization: `Bearer ${token}`,
            },
            cache: !isTesting ? 'no-store' : undefined,
        })
            .then(response => {
                if (actionRoot === 'UPDATE_CONSUMER_DATA') {
                    console.log('fetch response', response)
                }

                if (response.status !== 200) {
                    if (response.status === 401) {
                        dispatch({
                            type: Types.TOKEN_EXPIRED,
                        })
                    }

                    return response.text()
                        .then(responseAsText => {
                            try {
                                const body = JSON.parse(responseAsText);
                                return failureCallback({
                                    response: {
                                        body,
                                        code: response.code,
                                        status: response.status ? response.status : "",
                                        statusText: response.statusText
                                    },
                                    dispatch,
                                    getState,
                                    message: body.message
                                })
                            } catch {
                                return failureCallback({ response, dispatch, getState })
                            }
                        })
                }

                if (hasJsonResponse) {
                    return response.json().then(data => {
                        return successCallback({
                            response,
                            dispatch,
                            data,
                            getState,
                        })
                    })
                } else {
                    return response.text().then(message => {
                        return successCallback({
                            response,
                            dispatch,
                            message,
                            getState,
                        })
                    })
                }
            })
            .catch(error => {
                failureCallback({ dispatch, getState, error })
            })
    }
}
