import React from 'react'

// Components
import LinkButton from '../../../ui/LinkButton'
import { Button, Dropdown, Icon } from 'semantic-ui-react'

// Styles
import Styles from './styles.module.css'
import StylesSchedule from '../../../pages/ChooseSchedule/RetraitBoutiqueDetail/LocaleButton/ButtonSmall/styles.module.css'
import { Link } from 'react-router-dom'

const HeaderSelectAnotherStore = ({
    currentLocale,
    handleSelect,
    eventEmitter,
    locales,
}) => {
    // Add schedule select in local array
    const handleAddLocale = schedule => {
        handleSelect(currentLocale, schedule)
    }

    let scheduleList
    const currentLocaleSchedules =
        locales &&
        locales[currentLocale.code] &&
        locales[currentLocale.code].schedules

    if (currentLocaleSchedules && currentLocale.currentSchedule) {
        scheduleList = currentLocaleSchedules.map((schedule, id) => (
            <LinkButton
                disabled={
                    currentLocale?.currentSchedule?.day === schedule?.day
                        ? true
                        : false
                }
                key={id}
                color="black"
                onClick={() => handleAddLocale(schedule)}
                className={`${StylesSchedule.localeButton} ${Styles.LocaleButton
                    } ${StylesSchedule.ButtonSmall} ${currentLocale?.currentSchedule?.day === schedule?.day
                        ? StylesSchedule.Active
                        : ''
                    }`}
            >
                <b className={StylesSchedule.LocalName}>{schedule?.day}</b>{' '}
                &nbsp;
                <span
                    key={id}
                    className={StylesSchedule.DetailSpan}
                >
                    {schedule?.time}
                </span>
            </LinkButton>
        ))
    }

    const trigger = (
        <button
            className={Styles.Btn}
            data-testid="button-current-locale"
        >
            {currentLocale && currentLocale.code === 'DOM' ?
                <Icon
                    size={window.innerWidth < 500 ? "big" : 'large'}
                    name="box"
                />
                :
                <Icon
                    size={window.innerWidth < 500 ? "big" : 'large'}
                    name="map marker alternate"
                />
            }

            {window.innerWidth > 500 &&
                <div
                    data-testid="info-current-locale"
                    className={Styles.Text}
                >
                    <div className={Styles.LocalName}>
                        {currentLocale &&
                            (currentLocale.name.indexOf(' - ') !== -1 ?
                                currentLocale.name.slice(0, currentLocale.name.indexOf(' - '))
                                :
                                currentLocale.name.length > 20 ?
                                    `${currentLocale.name.slice(0, 20)}...`
                                    :
                                    currentLocale.name)
                        }
                    </div>
                    <div className={Styles.LocalDay}>
                        {currentLocale &&
                            currentLocale.currentSchedule &&
                            currentLocale.currentSchedule.day}
                    </div>
                </div>
            }
        </button>
    )

    return (
        <div className={Styles.ButtonContainer}>
            <Dropdown
                trigger={trigger}
                icon={false}
                className={Styles.Dropdown}
            >
                <Dropdown.Menu
                    className={Styles.Panel}
                    data-testid="menu-change-current-locale"
                >
                    <div
                        data-testid="current-locale"
                        className={Styles.StoreInformation}
                    >
                        <div className={Styles.LocalName}>
                            Boutique {currentLocale && currentLocale.name}
                        </div>
                        {currentLocale && currentLocale.code !== 'DOM' &&
                            <div>
                                {currentLocale &&
                                    currentLocale.address &&
                                    currentLocale.address.street}{' '}
                                {currentLocale &&
                                    currentLocale.address &&
                                    currentLocale.address.zip}
                            </div>
                        }
                    </div>
                    <Dropdown.Menu
                        scrolling
                        className={Styles.DropdownMenu}
                        data-testid="dropdown-change-current-locale"
                    >
                        <span data-testid="button-small">{scheduleList}</span>
                    </Dropdown.Menu>

                    <Dropdown.Divider className={Styles.Divider} />

                    <div className={Styles.BtnChangeStore} id="buttonHolder">
                        {window.location.pathname.includes('commande') ?
                            <Button
                                as={Link}
                                to="/choisir-un-magasin"
                                icon
                                labelPosition="right"
                                size="medium"
                                basic
                                onClick={() =>
                                    eventEmitter.emit('boutique-unclick')
                                }
                                data-testid="main-boutique-unclick"
                            >
                                Changer de boutique
                                <Icon name="right arrow" />
                            </Button>
                            :
                            <Button
                                as={Link}
                                to="/commande"
                                icon
                                labelPosition="right"
                                size="medium"
                                color="black"
                                id="greenButton"
                                data-testid="main-boutique-unclick"
                            >
                                Revenir à ma vente
                                <Icon name="right arrow" />
                            </Button>
                        }
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default HeaderSelectAnotherStore
