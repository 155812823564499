import { useCallback, useEffect } from 'react'
import {
    Combobox,
    ComboboxInput,
    ComboboxPopover,
    ComboboxList,
    ComboboxOption,
} from '@reach/combobox'
import '@reach/combobox/styles.css'
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete'
import Styles from './styles.module.css'
import { Image } from 'semantic-ui-react'
import closeMap from '../../../../../../assets/closeMap.png'
import geolocalizeIcon from '../../../../../../assets/geolocalize.svg'
import searchGlass from '../../../../../../assets/search.svg'

const PlacesAutocomplete = ({
    type,
    mapRef,
    setSelectedLatLng,
    isPositionAbsolute,
    locales,
    geolocalizeUser,
    setIsHover,
    setSearchedLocale,
    searchedLocale
}) => {

    const {
        ready,
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: ['fr'] },
            types: ['address'],
        },
        debounce: 300,
        cache: 24 * 60 * 60,
    })

    function calculateDistance(latlng1, latlng2) {
        const { lat: lat1, lng: lng1 } = latlng1
        const { lat: lat2, lng: lng2 } = latlng2
        const toRad = angle => (Math.PI / 180) * angle
        const R = 6371 // Earth's radius in km

        const dLat = toRad(lat2 - lat1)
        const dLng = toRad(lng2 - lng1)

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRad(lat1)) *
            Math.cos(toRad(lat2)) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
        const distance = R * c

        return distance
    }

    const handleSelect = useCallback(
        async address => {
            setIsHover(null)
            setValue(address, false)
            clearSuggestions()

            const results = await getGeocode({ address })
            const { lat, lng } = await getLatLng(results[0])

            let nearestLocale = null
            let minDistance = Infinity
            for (const locale of locales) {
                const distance = calculateDistance(
                    { lat, lng },
                    locale.geolocation
                )
                if (distance < minDistance) {
                    minDistance = distance
                    nearestLocale = locale
                }
            }

            if (nearestLocale && mapRef && mapRef.current) {
                clearSuggestions()
                mapRef.current.panTo({ lat, lng })
                mapRef.current.setZoom(13)
            }

            setSelectedLatLng({ lat: lat, lng: lng })
            document.getElementById("combo-box-input").blur()
        },
        [mapRef]
    )

    useEffect(() => {
        document.getElementById("combo-box-input").blur()
    }, [])


    return (
        <div className={Styles.autocompleteContainer}>
            <Combobox
                onSelect={handleSelect}
                aria-label="renseignez une adresse"
                className={
                    isPositionAbsolute
                        ? Styles.comboboxContainerAbsolute
                        : Styles.comboboxContainer
                }
            >
                <ComboboxInput
                    value={type === 'address' ? value : searchedLocale}
                    spellcheck='false'
                    autocorrect='off'
                    autocomplete="off"
                    id='combo-box-input'
                    onChange={e => {
                        if (type === 'address') {
                            setValue(e.target.value)
                        }
                        else if (type === 'boutique') {
                            setSearchedLocale(e.target.value)
                        }
                    }}
                    disabled={!ready}
                    placeholder={
                        window.innerWidth > 1000
                            ? type === 'address'
                                ? 'Saisissez une adresse, un code postal, un arrondissement'
                                :
                                type === 'boutique' && 'Saisissez le nom de votre boutique'
                            : type === 'address'
                                ? 'Saisissez une adresse...'
                                :
                                type === 'boutique' && 'Saisissez le nom de la boutique'

                    }
                    className={Styles.comboboxInput}
                />
                {type === 'address' &&
                    <ComboboxPopover className={Styles.comboboxPopover}>
                        <ComboboxList>
                            {status === 'OK' &&
                                data.map(({ place_id, description }, index) => {
                                    return (
                                        <ComboboxOption
                                            key={place_id}
                                            value={description}
                                        />
                                    )
                                })}
                        </ComboboxList>
                    </ComboboxPopover>
                }
                {type === 'address' ?
                    value.length > 0 ?
                        <Image
                            src={closeMap}
                            alt="réinitialiser"
                            className={Styles.image}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                mapRef.current.panTo({
                                    lat: 48.90,
                                    lng: 2.41,
                                })
                                setSelectedLatLng(null)
                                setValue('')
                                mapRef.current.setZoom(11)
                            }}
                        />
                        :
                        <Image
                            src={searchGlass}
                            alt="Rechercher une adresse"
                            className={Styles.image}
                        />
                    : type === 'boutique' &&
                        searchedLocale.length > 0 ?
                        <Image
                            src={closeMap}
                            alt="réinitialiser"
                            className={Styles.image}
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                mapRef.current.panTo({
                                    lat: 48.90,
                                    lng: 2.41,
                                })
                                setSelectedLatLng(null)
                                setSearchedLocale('')
                                mapRef.current.setZoom(11)
                            }}
                        />
                        :
                        <Image
                            src={searchGlass}
                            alt="Rechercher une adresse"
                            className={Styles.image}
                        />
                }
            </Combobox>
            {type === 'address' && <div className={Styles.geolocButtonContainer}><div onClick={() => geolocalizeUser()} className={Styles.geolocButton}><Image title="Autour de moi" src={geolocalizeIcon} style={window.innerWidth > 1024 ? { width: '24px', height: '24px' } : { width: '35px', height: '35px' }} /></div></div>}
        </div>
    )
}

export default PlacesAutocomplete
