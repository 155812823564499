import { Cart } from '../../../../../models'
const convertToParseableRepresentation = (dateAsString) => dateAsString.replace(/-/g, '/')

export const mapStateToProps = ({
    currentUser,
    currentLocale,
    currentGlobalOrder,
    cart: { list, items: quantities },
    order,
    products,
    available: { products: available },
}) => {
    const cart = new Cart({
        list,
        quantities,
        products,
        available,
        validateConsumer: true,
        isLocalePrivate: currentLocale && !!currentLocale.private,
        isLocaleHomeDelivery: currentLocale && currentLocale.code === 'DOM',
        currentLocale,
    })

    const amountTotal = cart.getTotalTTC()
    const userCredit = currentUser.current_credit

    const now = new Date()
    return {
        allowCreditPayment: amountTotal <= userCredit,
        blockInStorePayment:
            currentLocale.type === 2 ||
            currentLocale.partner ||
            currentLocale.private ||
            currentGlobalOrder.block_instore_payment,
        blockOrderChanges: currentGlobalOrder.block_order_changes,
        globalOrderAlreadyClosed: {
            distributionDate: new Date(currentGlobalOrder.distribution_date),
            ordersClosingDate: new Date(convertToParseableRepresentation(currentGlobalOrder.order_end)),
            status: order && typeof order.globalOrderAlreadyClosed === 'boolean' ? order.globalOrderAlreadyClosed : new Date(convertToParseableRepresentation(currentGlobalOrder.order_end)) < now ? true : false,
        },
        isHomeDelivery: currentLocale.code === 'DOM',
        isOrderLocalePrivate: currentLocale && currentLocale.private,
        loadingPaymentTokens: currentUser.loading,
        orderreservation_id: order.id,
        paymentTokens: currentUser.payment_tokens || [],
        serverError: order && !!order.error,
    }
}
