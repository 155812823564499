import { useState, useRef, useEffect, useCallback } from 'react'
import { GoogleMap, InfoWindow, Marker, MarkerClusterer } from '@react-google-maps/api'
import { Image, Form, Icon, Button, Header, Checkbox } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { updateConsumerData, login, storeTempLivdomDetails, fetchContentfulZipcodes } from '../../../../../actions'

import usePlacesAutocomplete, {
    getGeocode,
    getZipCode,
} from 'use-places-autocomplete'

import { mapStyle } from './mapStyle'
import livdomLogo from '../../../../../assets/livdom-logo.svg'
import bonhommeCagette from '../../../../../assets/bonhomme_cagette.svg'
import ChevronDown from '../../../../../assets/chevron-down.svg'
import ChevronUp from '../../../../../assets/chevron-up.svg'
import connectLogo from '../../../../../assets/connect.svg'
import connectLogoWhite from '../../../../../assets/connect-white.svg'
import tomateRouge from '../../../../../assets/tomate-rouge.svg'
import tomateJaune from '../../../../../assets/tomate-jaune.svg'
import camion from '../../../../../assets/camion.svg'

import Styles from './styles.module.css'

import BoutiqueTile from './BoutiqueTile'
import CardPartenaire from '../CardPartenaire'
import DateSlider from '../DateSlider'
import AddressForm from './AddressForm'
import InfoModal from '../../../../ui/InfoModal'

const boundsIdf = { 'north-lat': 49.20, 'east-lng': 3.48, 'south-lat': 48.13611, 'west-lng': 1.47944 }

const options = {
    gestureHandling: "greedy",
    styles: mapStyle,
    disableDefaultUI: true,
    zoomControl: true,
}

const TheMap = ({
    selectedTab,
    mapCenter,
    openPanel,
    setOpenPanel,
    currentUser,
    zipcodes,
    handleSelect,
    setMapCenter,
    handleLocaleClick,
    selectedLatLng,
    eventEmitter,
    locales,
    setAddressError,
    handleLocaleUnclick,
    selectedLocaleMap,
    livdomLocale,
    mapRef,
    setSelectedLocaleMap,
    login,
    isHover,
    setIsHover,
    updateConsumerData,
    storeTempLivdomDetails,
    fetchContentfulZipcodes
}) => {
    const sideListRef = useRef()
    const [zoom, setZoom] = useState(11)
    const [map, setMap] = useState(null)
    const [selectedMarker, setSelectedMarker] = useState([])
    const [sideLocales, setSideLocales] = useState([])
    const [scrollIndex, setScrollIndex] = useState(null)
    const [toggleScreen, setToggleScreen] = useState('form')
    const [livdomStep, setLivdomStep] = useState(0)
    const [showForm, setShowForm] = useState(false)
    const [containerColor, setContainerColor] = useState('#fef4d4')
    const [mapBounds, setMapBounds] = useState(null)
    const [panTimeout, setPanTimeout] = useState(false)
    const [loginError, setLoginError] = useState(false)
    const [outOfRange, setOutOfRange] = useState(false)

    const [boutiques, setBoutiques] = useState(true)
    const [retraits, setRetraits] = useState(true)
    const [camions, setCamions] = useState(true)
    const [filteredLocales, setFilteredLocales] = useState(locales)

    const [lastname, setLastname] = useState(null)
    const [firstname, setFirstname] = useState(null)
    const [telephone, setTelephone] = useState(null)
    const [address_street, setAddress_street] = useState(null)
    const [address_city, setAddress_city] = useState(null)
    const [address_zip, setAddress_zip] = useState(null)
    const [address_code, setAddress_code] = useState(null)
    const [address_instructions, setAddress_instructions] = useState(null)

    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)

    const {
        value,
        setValue,
        suggestions: { status, data },
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: ['fr'] },
            types: ['address'],
        },
        debounce: 300,
        cache: 24 * 60 * 60,
    })

    async function handleSelectAddress(address) {
        clearSuggestions()
        setValue(address, false)

        const results = await getGeocode({ address })

        setAddress_city(results[0].address_components[2].long_name)
        setAddress_zip(getZipCode(results[0], false))
        setAddress_street(`${results[0].address_components[0].long_name} ${results[0].address_components[1].long_name}`)
    }

    const livdomLocaleSchedule = livdomLocale && livdomLocale.schedules.filter(function (val) {
        if (val === '' || val === undefined || val == null) {
            return false
        }
        return true
    })

    const handleAddLocale = schedule => {
        handleSelect(livdomLocale, schedule)
    }

    const onMapClick = useCallback(e => {
        document.getElementById("combo-box-input").blur()
        setIsHover(null)
        setSelectedMarker(current => [
            ...current,
            {
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
                time: new Date(),
            },
        ])
    }, [])

    const handleLoginSubmit = (e) => {
        e.preventDefault();
        login({ email, password })
            .then(isOK => {
                if (!isOK) {
                    setLoginError(!loginError)
                }
                else {
                    setToggleScreen('form')
                    setContainerColor('#075244')
                }
            })
    }

    const zoomInLocaleMap = locale => {
        if (window.innerWidth > 1024) {
            // Zoom to boutique location
            const { lat, lng } = locale.geolocation
            if (mapRef && mapRef.current) {
                mapRef.current.panTo({ lat: lat + 0.01 * Math.max(14 - zoom, 1), lng: lng - 0.022 * Math.max(14 - zoom, 1) })
            }
        }
    }

    const panInLocaleMap = (panRatio) => {
        if (window.innerWidth > 1024) {
            if (mapRef && mapRef.current) {
                mapRef.current.panBy(-200 * panRatio, 0)
            }
        }
    }

    const handleUpdateConsumerData = (e) => {
        updateConsumerData({ firstname, lastname, telephone, address_street, address_zip, address_city, address_code, address_instructions })
    };

    const updateStoreTempLivdomDetails = (e) => {
        storeTempLivdomDetails({ firstname, lastname, address_street, address_zip, address_city, address_instructions })
        setLivdomStep(2)
    };

    function handleMarkerAction(locale, hovered) {
        setIsHover(hovered)
        document.getElementById("combo-box-input").blur()
        setSelectedLocaleMap(locale)

        // Getting mapBounds and 
        let mapBoundsKeys = Object.keys(mapBounds)
        let lngKey
        mapBoundsKeys.forEach(m => {
            if (mapBounds[m].hi < 10) {
                lngKey = m
            }
        })

        // Differentiating between hover and click
        if (!hovered) {
            zoomInLocaleMap(locale)
            setScrollIndex(sideLocales.findIndex(l => l.code === locale.code))
            return
        }

        let lngDelta = (locale.geolocation.lng - mapBounds[lngKey].lo) / (mapBounds[lngKey].hi - mapBounds[lngKey].lo)
        if (hovered && zoom > 12 && lngDelta < 0.37) {
            if (lngDelta < 0.27) {
                panInLocaleMap(1)
            }
            else if (lngDelta < 0.32) {
                panInLocaleMap(0.75)
            }
            else {
                panInLocaleMap(0.5)
            }
            setPanTimeout(true)
        }
    }

    const mapContainerStyle = (window.innerWidth > 1024 ?
        selectedTab === 'livdom' ?
            {
                height: '87vh',
                width: '100%',
            } :
            {
                height: 'calc(87vh - 106px)',
                width: '100%',
            }
        : window.innerWidth < 500 ?
            selectedTab === 'livdom' ?
                {
                    height: 'calc(65vh + 106px)',
                    width: '100%',
                    marginTop: 0,
                }
                :
                {
                    height: '65vh',
                    width: '100%',
                    marginTop: 0,
                }
            :
            {
                height: '90vh',
                width: '100%',
                marginTop: 0,
            }
    )

    var clusterStyles = [
        {
            textColor: 'white',
            url: 'https://kbg.s3.eu-west-1.amazonaws.com/images/greenCircle.png',
            height: 40,
            width: 40
        },
        {
            textColor: 'white',
            url: 'https://kbg.s3.eu-west-1.amazonaws.com/images/greenCircle.png',
            height: 45,
            width: 45
        },
        {
            textColor: 'white',
            url: 'https://kbg.s3.eu-west-1.amazonaws.com/images/greenCircle.png',
            height: 50,
            width: 50
        }
    ];

    const mcOptions = {
        gridSize: 60,
        styles: clusterStyles,
        maxZoom: 13
    }

    const onMapLoad = useCallback(map => {
        mapRef.current = map
    }, [])

    function onBoundsChanged() {
        let bounds = mapRef.current.getBounds()

        // Verifiy not out of Ile de France bounds
        if (bounds) {
            let boundsKeys = Object.keys(bounds)
            let lngKey
            let latKey
            boundsKeys.forEach(m => {
                if (bounds[m].hi < 10) {
                    lngKey = m
                }
                else {
                    latKey = m
                }
            })

            if (bounds[latKey].hi < boundsIdf['south-lat'] || bounds[latKey].lo > boundsIdf['north-lat'] || bounds[lngKey].hi < boundsIdf['west-lng'] || bounds[lngKey].lo > boundsIdf['east-lng']) {
                setOutOfRange(true)
                return
            }
        }

        if (window.innerWidth > 1024) {
            setMapBounds(bounds)
            setZoom(mapRef.current.getZoom())
            if (bounds) {
                let newSideLocales = []
                filteredLocales.forEach(l => {
                    if (bounds.contains({ lat: l.geolocation.lat, lng: l.geolocation.lng })) {
                        newSideLocales.push(l)
                    }
                })
                setSideLocales(newSideLocales)
            }
        }
    }

    useEffect(() => {
        if (mapRef.current) {
            onBoundsChanged()
        }
    }, [filteredLocales])

    useEffect(() => {
        if (sideLocales.length === 0) {
            setZoom(11)
        }
    }, [sideLocales])

    useEffect(() => {
        setAddress_instructions(currentUser.address_instructions || '')
        if (currentUser.loggedin) {
            setFirstname(currentUser.firstname)
            setLastname(currentUser.lastname)
            setTelephone(currentUser.telephone)
            setAddress_code(currentUser.address_code || '')
            if (currentUser.address_city) {
                let userAddress = `${currentUser.address_street}, ${currentUser.address_city}, France`
                handleSelectAddress(userAddress)
            }
            setContainerColor('#075244')
            setLivdomStep(1)
        }
    }, [currentUser])

    useEffect(() => {
        fetchContentfulZipcodes()
    }, [])

    useEffect(() => {
        let filterArray = [retraits ? 2 : null, camions ? 1 : null, boutiques ? 0 : null]

        let tempLocales = []
        locales && locales.forEach(l => {
            if (filterArray.includes(l.type)) {
                tempLocales.push(l)
            }
        })
        setFilteredLocales(tempLocales)
    }, [locales, retraits, camions, boutiques])


    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    return (
        <>
            {selectedTab === 'livdom' &&
                <div className={Styles.mapOverlay}>
                    <div
                        className={Styles.livdomBox}
                        style={currentUser.loggedin ? { backgroundColor: '#075244' } : {}}
                    >
                        {livdomStep === 0 ?
                            <>
                                <div className={toggleScreen === 'form' ? Styles.connectContainer : Styles.connectContainerSelected}>
                                    {toggleScreen === 'form' ?
                                        <Image
                                            src={connectLogo}
                                            className={Styles.formLogo}
                                            alt="connexion"
                                        />
                                        :
                                        <Image
                                            src={connectLogoWhite}
                                            className={Styles.formLogo}
                                            alt="connexion"
                                        />
                                    }
                                    <h4>Je me connecte</h4>
                                    {toggleScreen === 'form' &&
                                        <Image
                                            onClick={() => setToggleScreen('connect')}
                                            src={ChevronDown}
                                            className={Styles.clickableLogo}
                                            style={{ marginTop: '0' }}
                                            alt="down"
                                        />
                                    }
                                    {toggleScreen === 'connect' &&
                                        <Form onSubmit={handleLoginSubmit} style={{ display: 'flex', flexDirection: 'column', paddingBottom: '25px', width: '60%' }}>
                                            <div className={Styles.Align}>
                                                <Form.Field
                                                    required
                                                    style={window.innerWidth > 1024 ? { width: '50%' } : { width: '100%' }}
                                                >
                                                    <label style={{ textAlign: 'start !important', color: 'white' }} htmlFor="lastname">Email</label>
                                                    <input
                                                        name="emal"
                                                        id="email"
                                                        style={{ opacity: '0.5' }}
                                                        placeholder=""
                                                        value={email}
                                                        onChange={e => setEmail(e.target.value)}
                                                    />
                                                </Form.Field>

                                                <Form.Field
                                                    required
                                                    style={window.innerWidth > 1024 ? { width: '50%' } : { width: '100%' }}
                                                >
                                                    <label style={{ textAlign: 'start !important', color: 'white' }} htmlFor="lastname">Mot de Passe</label>
                                                    <input
                                                        name="password"
                                                        id="password"
                                                        type="password"
                                                        style={{ opacity: '0.5' }}
                                                        placeholder=""
                                                        value={password}
                                                        onChange={e => setPassword(e.target.value)}
                                                    />
                                                </Form.Field>
                                            </div>
                                            {loginError && <p style={{ color: 'red', margin: '5px 0' }}>Erreur de connexion, veuillez réessayer</p>}

                                            <Form.Button
                                                icon
                                                style={window.innerWidth > 1024 ? { width: '60%', alignSelf: 'center' } : { width: '100%', alignSelf: 'center' }}
                                                color="black"
                                                fluid
                                                labelPosition="right"
                                                loading={currentUser.loading ? true : false}
                                                size="large"
                                            >
                                                Connexion
                                                <Icon name="right arrow" />
                                            </Form.Button>
                                            <Link to="/creer-un-compte">
                                                <Button
                                                    icon
                                                    style={window.innerWidth > 1024 ? { width: '60%', alignSelf: 'center' } : { width: '100%', alignSelf: 'center' }}
                                                    secondary
                                                    fluid
                                                    labelPosition="right"
                                                    size="large"
                                                >
                                                    Créer mon compte
                                                    <Icon name="right arrow" />
                                                </Button>
                                            </Link>
                                            <Header
                                                as="h3"
                                                textAlign="center"
                                                style={{ color: 'white' }}
                                            >
                                                Un trou de mémoire?
                                                <Header.Subheader style={{ color: 'white' }}>
                                                    Vous pouvez créer un nouveau mot de passe
                                                </Header.Subheader>
                                            </Header>

                                            <Link to="/mot-de-passe-oublie">
                                                <Button
                                                    icon
                                                    style={window.innerWidth > 1024 ? { width: '60%', alignSelf: 'center' } : { width: '100%', alignSelf: 'center' }}
                                                    fluid
                                                    labelPosition="right"
                                                    size="small"
                                                >
                                                    Mot de passe oublié
                                                    <Icon name="right arrow" />
                                                </Button>
                                            </Link>
                                        </Form>
                                    }
                                </div>
                                <div className={Styles.mainContainer} style={containerColor ? { backgroundColor: containerColor } : {}}>
                                    <>
                                        {toggleScreen === 'connect' &&
                                            <Image
                                                onClick={() => setToggleScreen('form')}
                                                src={ChevronUp}
                                                className={Styles.clickableLogo}
                                                alt="down"
                                            />
                                        }
                                        <Image
                                            src={livdomLogo}
                                            style={{ width: '35px' }}
                                            alt="livdom"
                                        />
                                        {currentUser.loggedin ? <h4>Je confirme mes informations de livraison</h4> : <h4 style={{ marginBottom: '20px' }}>Je choisis mon adresse de livraison</h4>}
                                        {toggleScreen === 'form' &&
                                            <div className={Styles.formContainer}>
                                                <AddressForm
                                                    handleSubmitForm={updateStoreTempLivdomDetails}
                                                    currentUser={currentUser}
                                                    lastname={lastname}
                                                    setLastname={setLastname}
                                                    firstname={firstname}
                                                    setFirstname={setFirstname}
                                                    telephone={telephone}
                                                    setTelephone={setTelephone}
                                                    value={value}
                                                    data={data}
                                                    setValue={setValue}
                                                    setAddress_street={setAddress_street}
                                                    address_zip={address_zip}
                                                    setAddress_zip={setAddress_zip}
                                                    setAddress_city={setAddress_city}
                                                    address_code={address_code}
                                                    setAddress_code={setAddress_code}
                                                    address_instructions={address_instructions}
                                                    setAddress_instructions={setAddress_instructions}
                                                    handleSelectAddress={handleSelectAddress}
                                                    zipcodes={zipcodes && zipcodes.content[0]}
                                                    formType={1}
                                                />
                                            </div>
                                        }
                                    </>
                                </div>
                            </>

                            : livdomStep === 1 ?
                                // Screen for loggedin users with or without an address on file
                                <div className={Styles.mainContainer} style={containerColor ? { backgroundColor: containerColor } : {}}>
                                    <h4 style={{ color: 'white' }}>{`${currentUser.firstname} ${currentUser.lastname}`}</h4>
                                    <div className={Styles.addressContainer}>
                                        {currentUser.address_street ?
                                            <><Icon name="dot circle" />
                                                <div style={{ textAlign: 'start', marginLeft: '15px' }}>
                                                    <p style={{ margin: '3px 0' }}>{`${currentUser.address_street}, ${currentUser.address_zip} ${currentUser.address_city}`}</p>
                                                    <p style={currentUser.telephone ? { margin: '3px 0' } : { margin: '3px 0', color: 'red' }}>{currentUser.telephone ? currentUser.telephone : 'Numéro de téléphone manquant'}</p>
                                                    <p style={{ margin: '3px 0' }}>{currentUser.address_instructions}</p>
                                                </div>
                                            </>
                                            :
                                            <p>Aucune adresse n'est enregistrée</p>
                                        }
                                    </div>
                                    <div className={Styles.buttonContainer}>
                                        <div onClick={() => setShowForm(!showForm)} className={Styles.lightButton}>
                                            {!showForm ? <Image
                                                src={ChevronDown}
                                                style={{ width: '20px', marginRight: '15px' }}
                                                alt="down"
                                            /> : <Image
                                                onClick={() => setToggleScreen('connect')}
                                                src={ChevronUp}
                                                style={{ width: '20px', marginRight: '15px' }}
                                                alt="up"
                                            />}
                                            <p style={{ margin: '0', color: '#075244' }}>
                                                {window.innerWidth > 1024 ?
                                                    currentUser.address_street ? 'Modifier mon adresse' : 'Ajouter une adresse'
                                                    :
                                                    currentUser.address_street ? 'Modifier' : 'Ajouter'}
                                            </p>
                                        </div>
                                        {currentUser.address_street && currentUser.telephone &&
                                            <div onClick={() => {
                                                setLivdomStep(2)
                                                setContainerColor('#FDF4D4')
                                            }} className={Styles.formNext}>
                                                <p style={{ color: 'white' }}><span>{window.innerWidth > 1024 ? 'Valider et choisir mon jour de livraison' : 'Valider et choisir'}</span>{"   >"}</p>
                                            </div>
                                        }
                                    </div>
                                    {showForm &&
                                        // Showing the form to fill in or amend address details
                                        <div className={Styles.formContainer} style={currentUser.address_street ? { backgroundColor: '#DFEBE8' } : { backgroundColor: '#fef4d4' }}>
                                            <AddressForm
                                                handleSubmitForm={handleUpdateConsumerData}
                                                currentUser={currentUser}
                                                lastname={lastname}
                                                setLastname={setLastname}
                                                firstname={firstname}
                                                setFirstname={setFirstname}
                                                telephone={telephone}
                                                setTelephone={setTelephone}
                                                value={value}
                                                data={data}
                                                setValue={setValue}
                                                setAddress_street={setAddress_street}
                                                address_zip={address_zip}
                                                setAddress_zip={setAddress_zip}
                                                setAddress_city={setAddress_city}
                                                address_code={address_code}
                                                setAddress_code={setAddress_code}
                                                address_instructions={address_instructions}
                                                setAddress_instructions={setAddress_instructions}
                                                handleSelectAddress={handleSelectAddress}
                                                zipcodes={zipcodes && zipcodes.content[0]}
                                                formType={2}
                                                setShowForm={setShowForm}
                                            />
                                        </div>
                                    }
                                </div>

                                : livdomStep === 2 &&
                                // Screen for choosing your sales date
                                <div className={Styles.mainContainer} style={containerColor ? { backgroundColor: containerColor } : {}}>
                                    <div className={Styles.sliderContainer}>
                                        <h4 style={{ color: '#075244' }}>Je choisis mon jour de livraison</h4>
                                        <Image
                                            src={bonhommeCagette}
                                            style={{ width: '50px', marginBottom: '10px' }}
                                            alt="livdom"
                                        />
                                        <DateSlider
                                            showHours={false}
                                            locale={livdomLocale}
                                            selectedLocale={livdomLocale}
                                            handleNavigate={handleAddLocale}
                                            localeSchedule={livdomLocaleSchedule}
                                        />
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            }
            <GoogleMap
                id="map"
                mapContainerStyle={mapContainerStyle}
                zoom={zoom}
                center={mapCenter}
                options={options}
                onClick={onMapClick}
                onLoad={onMapLoad}
                onBoundsChanged={onBoundsChanged}
                onUnmount={onUnmount}
            >
                <MarkerClusterer options={mcOptions} >
                    {clusterer =>
                        filteredLocales.map(locale => (
                            <>
                                <Marker
                                    key={`${locale.lat}-${locale.lng}`}
                                    id={locale.code}
                                    style={{ position: 'relative' }}
                                    clusterer={clusterer}
                                    position={{
                                        lat: locale.geolocation.lat,
                                        lng: locale.geolocation.lng,
                                    }}
                                    onClick={() => {
                                        setIsHover(null)
                                        handleMarkerAction(locale, false)
                                    }}
                                    onMouseOver={() => {
                                        if (isHover === null) {
                                            handleMarkerAction(locale, true)
                                        }
                                    }}
                                    onMouseOut={() => {
                                        if (isHover) {
                                            if (panTimeout) {
                                                setTimeout(() => {
                                                    setIsHover(null)
                                                }, 1000)
                                                setPanTimeout(false)
                                            }
                                            else {
                                                setIsHover(null)
                                            }
                                        }
                                    }}
                                    icon={{
                                        url:
                                            locale.type === 0 ?
                                                tomateRouge
                                                : locale.type === 2 ?
                                                    tomateJaune
                                                    : camion,
                                        origin: new window.google.maps.Point(0, 0),
                                        anchor: locale.partner
                                            ? new window.google.maps.Point(16, 16)
                                            : new window.google.maps.Point(25, 25),
                                        scaledSize: locale.type === 1
                                            ? new window.google.maps.Size(40, 40)
                                            : new window.google.maps.Size(30, 30),
                                    }}
                                >
                                    {selectedLocaleMap && selectedLocaleMap === locale && typeof isHover === 'boolean' &&
                                        <InfoWindow
                                            disableAutoPan={true}
                                            headerDisabled={true}
                                        >
                                            <BoutiqueTile
                                                isHover={isHover}
                                                setIsHover={setIsHover}
                                                currentUser={currentUser}
                                                locale={locale}
                                                handleLocaleClick={handleLocaleClick}
                                                handleLocaleUnclick={handleLocaleUnclick}
                                                eventEmitter={eventEmitter}
                                                handleNavigate={handleSelect}
                                                mapRef={mapRef}
                                                localeLength={locales.length}
                                                openPanel={openPanel}
                                                setOpenPanel={setOpenPanel}
                                                mapCenter={mapCenter}
                                                setMapCenter={setMapCenter}
                                            />
                                        </InfoWindow>
                                    }
                                </Marker>
                            </>
                        ))
                    }
                </MarkerClusterer>
                {selectedLatLng !== null &&
                    <Marker
                        position={{
                            lat: selectedLatLng.lat,
                            lng: selectedLatLng.lng,
                        }}
                    />
                }
                {selectedTab !== 'livdom' && zoom > 12 && sideLocales.length > 0 &&
                    <div
                        className={
                            window.innerWidth > 1024
                                ? Styles.SideBoutiqueListContainer
                                : Styles.hide
                        }
                        ref={sideListRef}
                    >
                        {sideLocales.map((locale, index) => {
                            return (
                                <CardPartenaire
                                    where={'sideMap'}
                                    locale={locale}
                                    handleMarkerAction={handleMarkerAction}
                                    currentUser={currentUser}
                                    selectedLocale={selectedLocaleMap}
                                    setSelectedLocale={setSelectedLocaleMap}
                                    handleLocaleClick={handleLocaleClick}
                                    handleLocaleUnclick={handleLocaleUnclick}
                                    eventEmitter={eventEmitter}
                                    handleNavigate={handleSelect}
                                    mapRef={mapRef}
                                    localeLength={sideLocales.length}
                                    openPanel={openPanel}
                                    index={index}
                                    setOpenPanel={setOpenPanel}
                                    mapCenter={mapCenter}
                                    setMapCenter={setMapCenter}
                                />
                            )
                        })}
                    </div>
                }
                {zoom < 13 && window.innerWidth > 1024 && selectedTab === 'map' &&
                    <div className={Styles.legendContainer}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                name="toggle_boutiques"
                                onChange={(e, data) => setBoutiques(data.checked)}
                                checked={boutiques}
                            />
                            <Image className={Styles.cardIcons} src={tomateRouge} alt="" />
                            <p style={{ margin: '0 0 0 12px', fontWeight: 'bold' }}>Boutiques et lieux de retrait Kelbongoo</p>
                        </div>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                name="toggle_retraits"
                                onChange={(e, data) => setRetraits(data.checked)}
                                checked={retraits}
                            />
                            <Image className={Styles.cardIcons} src={tomateJaune} alt="" />
                            <p style={{ margin: '0 0 0 12px', fontWeight: 'bold' }}>Retrait chez un commerçant partenaire</p>
                        </div>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                name="toggle_camions"
                                onChange={(e, data) => setCamions(data.checked)}
                                checked={camions}
                            />
                            <Image className={Styles.cardIcons} src={camion} alt="" />
                            <p style={{ margin: '0 0 0 12px', fontWeight: 'bold' }}>Retrait au camion Kelbongoo</p>
                        </div>
                    </div>
                }
            </GoogleMap>
            <InfoModal
                header='ON VOUS A PERDUS...'
                icon='banana'
                closeButton={false}
                modalClass='VersionUpdate'
                body={<p><b>Kelbongoo grandit et développe sa zone d'activité.</b><br /><br />Mais pour le moment, nos points retraits et services de livraison sont limités à <b>la région Ile-de-France.</b><br /><br />Si vous avez des difficultés pour commander, <b>n'hésitez pas à nous écrire à contact@kelbongoo.com 👋!</b></p>}
                show={outOfRange}
                actionButton={{
                    text: "Fermer",
                    onClick: () => {
                        setOutOfRange(false)
                        setMapCenter({ lat: 48.866667, lng: 2.333333 })
                        setZoom(11)
                    },
                    fluid: true,
                    color: 'green'
                }}
            />
        </>
    )
}

const mapStateToProps = ({ currentUser, livdomZipcodes }) => {
    return {
        currentUser,
        zipcodes: livdomZipcodes
    }
}

const mapDispatchToProps = dispatch => {
    return {
        login: payload => dispatch(login(payload)),
        updateConsumerData: params => dispatch(updateConsumerData(params)),
        storeTempLivdomDetails: params => dispatch(storeTempLivdomDetails(params)),
        fetchContentfulZipcodes: params => dispatch(fetchContentfulZipcodes(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TheMap);